<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar
          </v-btn>
          <v-btn block color="success darken mt-3" dark @click="form()">
            <v-icon small class="mr-2">mdi mdi-script-text-outline</v-icon> Gerar HTML
          </v-btn>
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table :headers="header" :items="objects" dense locale="pt-BR" :search="search" :items-per-page="15">
        <template v-slot:item.startDate="{ item }">
          {{ item.startDate | formatDate }}
        </template>
        <template v-slot:item.endDate="{ item }">
          <span v-if="item.endDate == '0000-00-00 00:00:00'">Em Aberto</span>
          <span v-else>{{ item.endDate | formatDate }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip label small text-color="white" :color="getStatusColor(item.status)">
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Vizualizar">
            <v-btn icon small tile v-if="item.status == 'Finalizado'">
              <v-icon @click="dialogView1(item)">mdi mdi-file-eye-outline</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Ações">
            <v-btn icon small tile>
              <v-icon @click="detailRequest(item.id)">mdi mdi-lan</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Editar" v-if="item.status !== 'Finalizado'">
            <v-btn icon small tile :disabled="item.status === 'Finalizado'">
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover" v-if="item.status !== 'Finalizado'">
            <v-btn icon small tile :disabled="item.status === 'Finalizado'">
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <!-- MODAL -->
    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" @close-dialog="loadData" width="1200px">
      <template>
        <v-form v-model="valid" ref="form1">
          <v-row>
            <v-col md="6" sm="12" cols="12">
              <b>Tipo de Solicitante *</b>
              <v-select
                :items="list"
                v-model="object.typeRequest"
                class="my-2"
                dense
                tile
                outlined
                color="primary"
                :rules="[requiredRule, spaceRule]"
              ></v-select>
            </v-col>
            <v-col md="6" sm="12" cols="12">
              <b>Tipo de Solicitação *</b>
              <v-select
                :items="request"
                v-model="object.type"
                class="my-2"
                dense
                tile
                outlined
                color="primary"
                :rules="[requiredRule, spaceRule]"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6" sm="12" cols="12">
              <b> Nome Completo *</b>
              <v-text-field
                dense
                tile
                outlined
                class="my-2"
                :rules="[requiredRule, spaceRule]"
                v-model="object.name"
              ></v-text-field>
            </v-col>
            <v-col md="4" sm="12" cols="12">
              <b> Email *</b>
              <v-text-field
                :rules="[requiredRule, validarEmail]"
                v-model="object.email"
                outlined
                tile
                dense
                class="my-2"
              ></v-text-field>
            </v-col>
            <v-col md="2" sm="12" cols="12">
              <b>Telefone *</b>
              <v-text-field
                :rules="[requiredRule, validarTelefone]"
                v-model="object.phone"
                v-mask="['(##) ####-####', '(##) #####-####']"
                outlined
                tile
                dense
                class="my-2"
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Pessoa Fisica -->
          <div v-if="object.typeRequest == 'Pessoa Física'">
            <div v-if="object.type == 'Retificação'">
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col md="5" sm="6" cols="6">
                  <b> Data Nascimento *</b>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="object.birthday"
                        placeholder="Data de Nascimento"
                        persistent-hint
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        @input="date = parseDate(object.birthday)"
                        v-on="on"
                        hide-details
                        dense
                        outlined
                        tile
                        class="my-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker :rules="requiredRule" v-model="date" no-title @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="5" sm="6" cols="6">
                  <b> CPF *</b>
                  <v-text-field
                    v-model="object.cpf"
                    hide-dtails
                    v-mask="'###.###.###-##'"
                    outlined
                    tile
                    dense
                    :rules="[validarCPF]"
                    class="my-0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>

          <!-- Pessoa Juridica -->
          <div v-if="object.typeRequest == 'Pessoa Jurídica'">
            <div v-if="object.type == 'Retificação'">
              <v-row>
                <v-col md="4" sm="12" cols="12">
                  <b> Nome Fantasia *</b>
                  <v-text-field
                    dense
                    tile
                    outlined
                    class="my-2"
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.nameFantasy"
                  ></v-text-field>
                </v-col>
                <v-col md="5" sm="12" cols="12">
                  <b> Razão Social * </b>
                  <v-text-field
                    :rules="[requiredRule, spaceRule]"
                    v-model="object.socialName"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
                <v-col md="3" sm="12" cols="12">
                  <b> CNPJ *</b>
                  <v-text-field
                    :rules="[validarCNPJ, spaceRule]"
                    v-model="object.cnpj"
                    v-mask="'##.###.###/####-##'"
                    outlined
                    tile
                    dense
                    class="my-2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>

          <v-divider class="my-2"></v-divider>

          <v-row>
            <v-col cols="12">
              <b> Descrição da Solicitação *</b>
              <v-textarea
                outlined
                rows="3"
                v-model="object.description"
                class="my-2"
                auto-grow
                :rules="[requiredRule, spaceRule]"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                v-model="object.consent"
                label="Eu confirmo a veracidade dos dados e concordo com a solicitação."
                color="success"
                value="Aceito"
                hide-details
                :rules="[requiredRule, spaceRule]"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template v-slot:actions="">
        <v-btn small depressed color="primary" class="mt-2" dark @click="resolveForm()">
          <span v-if="!edit"><v-icon small class="mr-2">mdi-plus</v-icon>Adicionar</span>
          <span v-if="edit"><v-icon small class="mr-2">mdi mdi-update</v-icon>Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>

    <!-- FORM EXTERNO -->
    <template>
      <v-dialog v-model="dialogForm" max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="headline"><v-icon>mdi mdi-script-text-outline</v-icon> Código fonte do formulário</span>
          </v-card-title>
          <v-card-text>
            <v-textarea 
              outlined
              rows="15"
              class="my-2"              
              v-model="formExternal"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="dialogForm = false">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'
import { mask } from 'vue-the-mask'
import { baseApiUrl, showError } from '@/global'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'SolicitacaoTitulares',
  directives: { mask },
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },

  data: () => ({
    baseRoute: 'solicitacao-titulares',
    title: 'Solicitação de Titulares',
    pageIcon: 'mdi mdi-account-details',

    objects: [],
    object: {},
    formExternal: [],

    dialog: false,
    dialogForm: false,
    search: null,
    valid: false,
    error: false,
    edit: false,
    loading: false,

    request: [
      { text: 'Acesso', value: 'Acesso' },
      { text: 'Retificação', value: 'Retificação' },
      { text: 'Exclusão', value: 'Exclusão' },
      { text: 'Portabilidade', value: 'Portabilidade' },
      { text: 'Objeção', value: 'Objeção' },
    ],
    list: [
      { text: 'Pessoa Física', value: 'Pessoa Física' },
      { text: 'Pessoa Jurídica', value: 'Pessoa Jurídica' },
    ],
    header: [
      { text: 'COD', value: 'id' },
      { text: 'Solicitante', value: 'name' },
      { text: 'Data de Abertura', value: 'startDate' },
      { text: 'Data de Fechamento', value: 'endDate' },
      { text: 'Status', value: 'status' },
      { text: 'Ação', value: 'action' },
    ],
  }),

  methods: {
    loadData() {
      this.$store.dispatch('setLoadingInfo', true)
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.objects = res.data
        })
        .catch(showError)
    },

    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },

    dialogUpdate(item) {
      this.object = item
      this.object.birthday = moment(new Date(this.object.birthday)).format('DD/MM/YYYY')
      this.edit = true
      this.dialog = true
    },

    resolveForm() {
      if (!this.valid) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text: 'Os campos não foram preenchidos corretamente, você pode verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },

    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object

      if (objects.typeRequest == 'Pessoa Física' && objects.type == 'Retificação') {
        objects.birthday = moment(new Date(objects.birthday)).format('YYYY-MM-DD')
      } else {
        delete objects.birthday
      }

      objects.startDate = moment(new Date())
      objects.status = 'Pendente'

      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id

      if (this.date) {
        objects.birthday = this.date
      } else {

        const data = this.object.birthday.split('/')
        const parseDate = `${data[2]}-${data[1]}-${data[0]}`

        objects.birthday = parseDate
      }

      delete objects.startDate
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
    
    getStatusColor(status) {
      if (status == 'Em andamento') {
        return '#FBC02D'
      } else if (status == 'Finalizado') {
        return '#4CAF50'
      } else if (status == 'Pendente') {
        return '#757575'
      }
    },
    
    form(){ 
      this.dialogForm = true 
      const url = `${baseApiUrl}/external-form`  
      axios
        .get(url )
        .then((res) => {
          this.$store.dispatch('setLoadingInfo', false)
          this.formExternal = res.data
        })
        .catch(showError)  
    },

    detailRequest(id) {
      const route = {
        name: 'SolicitacaoTitularesPai',
        params: { id: id },
      }
      this.$router.push(route)
    },

  },

  mounted() {
    this.loadData()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>